import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GranTypeEnum } from '@interfaces/token/gran.type.enum';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private clientID = environment.client.name;
  private clientSecret = environment.client.secret;
  private clientCredentials: string;

  private authHandler$ = combineLatest({
    tokens: this.authService.getObservable('tokens'),
    refreshing: this.authService.getObservable('refreshing'),
  });

  constructor(private authService: AuthService) {
    this.clientCredentials = btoa(`${this.clientID}:${this.clientSecret}`);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isApiReq = request.url.includes(environment.api);

    if (!isApiReq) {
      return next.handle(request);
    }

    return this.authHandler$.pipe(
      first(),
      switchMap(({ tokens, refreshing }) => {
        if (!tokens) {
          return next.handle(
            this.setRequestToken(request, this.clientCredentials, 'Basic'),
          );
        }

        const isRefreshRequest =
          request.body?.grant_type === GranTypeEnum.refresh_token;

        if (isRefreshRequest) return next.handle(request);

        const unixDate = Math.floor(Date.now() / 1000);
        const expired = tokens.expires_in <= unixDate;

        // Test que resta 5 minutos menos a la expiracion para refrescar con mas frecuencia.
        // const expired = tokens.expires_in - (5 * 60) <= unixDate;

        if (!expired) {
          return next.handle(
            this.setRequestToken(request, tokens.access_token),
          );
        }

        if (!refreshing) this.authService.refresh();

        return this.authHandler$.pipe(
          filter((_) => !_.refreshing),
          first(),
          switchMap(({ tokens: _t }) =>
            next.handle(this.setRequestToken(request, _t.access_token)),
          ),
        );
      }),
    );
  }

  setRequestToken(
    req: HttpRequest<unknown>,
    accessToken: string,
    authProp: string = 'Bearer',
  ): HttpRequest<unknown> {
    return req.clone({
      setHeaders: {
        Authorization: `${authProp} ${accessToken}`,
      },
    });
  }
}
